<template>
  <div>
    <div style="margin-bottom: 20px;">
<!--        <template v-if="dataHeader && dataHeader.type == 'PurchaseInvoice'">-->
          <report-header1 v-if="template && template.header_template == 1" :inner-template="template" :company="company"></report-header1>
          <report-header2 v-if="template && template.header_template == 2" :inner-template="template" :company="company"></report-header2>
          <report-header3 v-if="template && template.header_template == 3" :inner-template="template" :company="company"></report-header3>

<!--        </template>-->

    </div>

  </div>
</template>

<script>




import ReportHeader1 from "@/view/content/printing-heads/reports/report/header1.vue";
import ReportHeader2 from "@/view/content/printing-heads/reports/report/header2.vue";
import ReportHeader3 from "@/view/content/printing-heads/reports/report/header3.vue";
import ApiService from "@/core/services/api.service";

export default {
  name: "ReportMainHeader",
  components: {ReportHeader3, ReportHeader2, ReportHeader1},
  props: {
    printType: null,
  },
  data() {
    return {
      mainRoute: 'settings/report_settings',
      template: [],
      company: [],
    }
  },
  methods:{
    getDataTemplate(){
      ApiService.get(`${this.mainRoute}?slug=${this.printType}`).then((response) => {
        this.template = response.data.data;
        this.company = response.data.data.company;
      });
    },
  },
  created() {
    this.getDataTemplate();
  }

}
</script>

<style>
@import '~quill/dist/quill.core.css';

@media print {
  @page {
    size: a4;
    margin: 15px 0;
  }

}


div#invoice:not(.pdf) * {
  /*page-break-inside: avoid;*/
  /*page-break-before: auto;*/
}

.header-print {
  /*position: fixed;*/
  top: 20px;
  width: 754px;
}


.header-print-space {
  width: 754px;
  display: block;
  height: 150px;
}

.footer-print {
  /*position: fixed;*/
  bottom: 0;
  width: 754px;
  /*page-break-after:always;*/
}


div#invoice:not(.pdf) table.report-container {
  page-break-after: always;
}

table.report-container {
  text-align: unset !important;
}

thead.report-header {
  display: table-header-group;
}

tfoot.report-footer {
  display: table-footer-group;
}

thead.no-report-header {
  display: contents;
  /*page-break-inside: avoid !important;*/
  /*page-break-after: avoid !important;*/
  /*page-break-before: avoid !important;*/
}

:not(.pdf) thead.no-report-header {
  display: contents;
  page-break-inside: avoid !important;
  page-break-after: avoid !important;
  page-break-before: avoid !important;
}

tfoot.no-report-footer {
  /*display: contents;*/
  display: block;
}

tfoot.no-report-footer tr {
  display: block;
}

table thead.report-header tr th, table thead.no-report-header tr th {
  border: 0 !important;
}

.main {
  width: 199mm;
}

.header-info {
  /*margin-bottom: 150px;*/
}

.report-header-cell {
  /*padding-bottom: 20px;*/
  font-weight: unset !important;
}

.break-inside-revert, .break-inside-revert * {
  break-inside: revert !important;
}

.page-break-inside-pdf {
  page-break-inside: avoid;
}

.invoice-box:not(.pdf) table thead.no-report-header,
.invoice-box:not(.pdf) tbody,
.invoice-box:not(.pdf) thead.no-report-header tr,
.invoice-box:not(.pdf) thead.no-report-header td,
.invoice-box:not(.pdf) tfoot.no-report-footer,
.invoice-box:not(.pdf) tfoot.no-report-footer tr,
.invoice-box:not(.pdf) tfoot.no-report-footer td {
  /*page-break-inside: auto !important;*/
  /*page-break-after: auto !important;*/
  /*page-break-before: auto !important;*/

  page-break-inside: unset !important;
  page-break-after: avoid !important;
  page-break-before: avoid !important;
}

/*.table tbody tr td {*/
/*  text-align: center !important;*/
/*}*/

.invoice-box:not(.pdf) tfoot.no-report-footer * {
  page-break-after: initial !important;
}

.title-1 {
  line-height: 1.2;
}

.title-2 {
  line-height: 1.2;
}

table[direction="ltr"] .table-border-radius {
  border-left: 0 !important;
}

table[direction="ltr"] .green-cc-3 {
  text-align: left;
}

table[direction="ltr"] .par-h {
  margin-left: unset;
  margin-right: 10px;
}

table[direction="ltr"] .justify-content-between {
  flex-direction: row-reverse;
}

table[direction="ltr"] .no-revert, table[direction="ltr"] .page-break-inside-pdf {
  flex-direction: revert;
}

table[direction="ltr"] .total-border {
  flex-direction: unset;
}

.margin-left-025rem {
  margin-left: 0.25rem;
}

.margin-right-025rem {
  margin-right: 0.25rem;
}

table[direction="ltr"] .margin-left-025rem {
  margin-right: 0.25rem;
  margin-left: unset;
}

table[direction="ltr"] .margin-right-025rem {
  margin-left: 0.25rem;
  margin-right: unset;
}

table[direction="ltr"] table tbody tr td:last-child, table[direction="ltr"] table thead tr th:last-child {
  border-left: 2px solid #1a5a4a !important;
}
</style>
