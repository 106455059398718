<template>
  <div>
    <div class="d-flex justify-content-between">
      <div>
          <p class="title-1 space-word-15" style="margin-bottom: 2px;line-height: 1.5;" v-if="business_name">{{business_name}}</p>
          <p class="title-1 space-word-15 title-1-size-20" style="margin-bottom: 2px;" v-if="business_name_en">{{business_name_en}}</p>
          <p class="title-2" style="margin-bottom: 2px;">
            <span> رقم الرخصة</span> <span>CR No. </span> <span>{{business_record}}</span>
            <template v-if="tax_no">
              <span> / </span>
              <span> الرقم الضريبي</span> <span>VAT No: </span> <span>{{tax_no}}</span>

            </template>
          </p>
      </div>
      <div class="d-flex">
        <div>
          <img v-if="rel_logo" :src="rel_logo" class="logo-w" alt="">
        </div>

      </div>
      <img  class="watermark" v-if="rel_logo && show_watermark" :src="rel_logo" alt="">
    </div>
    <template>
      <h3 style="text-align: center;font-size: 20px;"><span>{{innerTemplate ? innerTemplate.title_ar: ''}}</span> <span>{{innerTemplate ? innerTemplate.title_en : '' }}</span></h3>
    </template>

  </div>
</template>

<script>
export default {
  name: "report-header-2",
  props: {company: {}, innerTemplate:{}},
  data(){
    return{
      rel_logo: null,
      business_name: null,
      business_name_en: null,
      tax_no: null,
      business_record: null,
      data:[],
      show_watermark: false,
    }
  },
  created() {
    // if(this.innerTemplate){
    //   this.show_watermark = this.innerTemplate.is_use_watermark;
    // }
    if (this.company){
      this.business_name = this.company.business_name ? this.company.business_name : null;
      this.business_name_en = this.company.business_name_en ? this.company.business_name_en : null;
      this.tax_no = this.company.tax_no ? this.company.tax_no : null;
      this.business_record = this.company.business_record ? this.company.business_record : null;
    }
    if (this.company && this.company.logo_url) {
      // this.getBase64Image(this.company.logo_url).then((res) => {
      this.rel_logo = this.company.logo_url;
      // })
    }else{
      // this.rel_logo = "https://upload.wikimedia.org/wikipedia/commons/e/eb/Creeperasha_Linux_test_image_upload.png";
      this.rel_logo = null;
    }


  }
}
</script>

<style scoped>
.title-1 {
  font-size: 30px;
  color: #1a5a4a;
}
.title-1-size-20 {
  font-size: 20px;
}
.logo-w {
  width: 100px;
  margin-left: 0;
}
.title-2 {
  font-size: 20px;
  color: #000;
  padding-top: 8px;
}
.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.08;
  z-index: 0;
  color: white;
  width: 579px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
