<template>
  <div class="d-flex justify-content-between">
    <div>
      <p class="title-2">{{innerTemplate ? innerTemplate.title_en : '' }}</p>
      <p class="title-2">{{innerTemplate ? innerTemplate.title_ar : '' }}</p>
    </div>
    <div class="d-flex">
      <div>
        <img id="rel_logo" v-if="rel_logo" :src="rel_logo" class="logo-w" alt="">
      </div>

    </div>

    <img  class="watermark" v-if="rel_logo && show_watermark" :src="rel_logo" alt="">
  </div>
</template>

<script>
export default {
  name: "report-header-1",
  props: {company: {}, innerTemplate:{}},
  data() {
    return {
      rel_logo: null,
      data: [],
      show_watermark: false,
      customer_type: null,
    }
  },
  methods: {

  },
  created() {
    // if(this.innerTemplate){
    //   this.show_watermark = this.innerTemplate.is_use_watermark;
    // }
    if (this.company && this.company.logo_url) {
      this.rel_logo = this.company.logo_url;
    }else{
      // this.rel_logo = "https://upload.wikimedia.org/wikipedia/commons/e/eb/Creeperasha_Linux_test_image_upload.png";
      this.rel_logo = null;
    }


  }
}
</script>

<style scoped>
.watermark {
  position: absolute;
  top: 0;
  bottom: 0;
  opacity: 0.08;
  z-index: 0;
  color: white;
  width: 579px;
  left: 0;
  right: 0;
  margin: auto;
}
</style>
